import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import {Helmet} from "react-helmet";
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPageTemplate = ({ data: { favicon, icono, post } }) => {
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }
  return (
    <Layout>
    <Helmet>
      { icono ? (
        <Helmet>
            <link rel="icon" href={favicon.childImageSharp.fixed.src} sizes="32x32" />
            <link rel="icon" href={icono.childImageSharp.fixed.src} sizes="192x192" />
            <link rel="apple-touch-icon" href={icono.childImageSharp.fixed.src} />
            <meta name="msapplication-TileImage" content={icono.childImageSharp.fixed.src} />
        </Helmet>
        ) : (
          <Helmet>
          </Helmet>
      )}
    </Helmet>
      <Seo title={post.title} description={post.excerpt} url={post.uri} />
      <article
        className="global-wrapper blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
      <h1 className="entry-title" itemProp="headline">{parse(post.title)}</h1>

        {!!post.content && (
          <section itemProp="articleBody">{parse(post.content)}</section>
        )}
      </article>
    </Layout>
  )
}
export default BlogPageTemplate

export const pageQuery = graphql`
  query BlogPageById(
    $id: String!
  ) {
    favicon: file(relativePath: { eq: "ico32.jpg" }) {
      childImageSharp {
        fixed(width: 32, quality:100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    icono: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fixed(width: 180, quality:100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    post: wpPage(id: { eq: $id }) {
      id
      content
      uri
      title
      seo {
        title
        canonical
        metaDesc
      }
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  }
`
